import React from "react";
import CoverageFinder from "@sm2dev/shingrix-gsk";

function App() {
  return (
    <CoverageFinder />
  );
}

export default App;
